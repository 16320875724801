import {Router, ActivatedRoute, NavigationEnd, NavigationStart} from '@angular/router';
import {UrlService} from '@app/services/url.service';
import {
  PLATFORM_ID,
  Component,
  OnInit,
  AfterViewInit,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import {isPlatformBrowser, DatePipe, ViewportScroller} from '@angular/common';
import {StorageService} from '@app/services/storage.service';
import {PromotionsService} from '@app/services/promotions.service';
import {Title, Meta} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {HtmlContentService} from '@app/services/html-content.service';
import {
  AppNotification,
  NotificationItem,
} from '@app/models/stu-notification';
import {environment} from '@env/environment';
import {
  PriceDetailsModel,
  PromotionDetailsModel,
} from '@app/models/interfaces/promotion-details.model';
import {GooglePlayReviewsService} from '../services/google-play-reviews.service';
import {ExternalReviews, Review} from '@app/models/external-reviews.model';
import {User} from '../models/user.model';
import {LazyLoad} from '@app/helpers/lazyload';
import {RechargeService} from '../services/recharge.service';
import {FormControl, Validators} from '@angular/forms';
import {CustomValidator} from '@app/helpers/validators';
import {StuTransactionAccountModel} from '@app/models/checkout/stu-transaction-model';
import {v4 as uuidv4, v4} from 'uuid';
import {RechargeModel} from '@app/models/recharge.model';
import {DataService} from '../services/data.service';
import {OnDestroy} from '@angular/core';
import {Observable} from 'rxjs';
import {TrackingComponent} from '../shared/components/tracking/tracking.component';
import {FingerprintService} from '../services/fingerprint.service';
import {DeepLinkType} from '@app/models/deep-link.model';
import {share} from 'rxjs/operators';
import {cubatel_service, CubatelServiceInterface, CubatelServiceSize} from '@app/home/cubatel-services.model';

declare let loadBootsrtapNative: Function;
const color = ['#6698FE', '#002db9', '#9B9A9A'];
const lazyLoad = LazyLoad;
const customValidator = CustomValidator;
const months = [
  'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
declare let $zoho;

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent extends TrackingComponent implements OnInit, AfterViewInit, OnDestroy {

  rechargeIsOpen = true;
  nautaIsOpen = false;
  loadingInit: Observable<boolean>;

  submitted = false;
  returnUrl: string;
  currentUser: User;

  notification: AppNotification;
  notificationItem: NotificationItem;
  notificationItems: NotificationItem[];
  loadNotificationItems: NotificationItem[];
  superPromotion = false;

  isPromotion = false;
  isPreRecharge = false;
  notificationEnabled = false;
  promotionEndDate: any;
  promotions: PriceDetailsModel[];
  offers: PriceDetailsModel[];
  offer: string;
  expiredDate: string;
  replacedBanner = false;

  from = 0;
  size = 20;
  hasMore: boolean;

  reviews: Review[] = [];
  reviewsMobile: Review[] = [];
  reviewsDesktop: Review[][] = [];

  user: User;
  userLevel = -1;
  userCounter = 1000000;
  appsCounter = 1000000;
  countriesCounter = 194;

  selectedOfferControl = new FormControl(null, Validators.required);
  phoneNumberControl = new FormControl(null, [
    Validators.required,
    customValidator.phoneValidator
  ]);
  nautaAccount = new FormControl(null, [
    Validators.required,
    customValidator.nautaAccountaValidator,
  ]);
  phoneNumberUntoched = false;
  selectedOfferUntoched = false;
  nautaAccountUntoched = false;
  promoTopHtml = '';
  promoDate = '';
  promoDateText = '';
  promotionAmountFullText = '';
  useStuCode = '';
  flashOfferEnabled = false;
  activeFragment = this.route.fragment.pipe(share());

  turnOnShein = environment.turn_on_shein;
  turnOnAmazon = environment.turn_on_amazon;

  cubatelServices = cubatel_service.map(x => {
    if (x.name == 'Recargas') {
      x.link = this.isAuthenticated() ? '/recargas' : '';
    }
    return x;
  }).filter(x => x.active);
  cubatelServiceSizes: CubatelServiceSize[];

  public innerWidth: any;

  title = 'Recargas a Cuba y más servicios - Cubatel';
  // tslint:disable-next-line:max-line-length
  description = 'Envía recargas móviles Cubacel y Nauta a tus familiares o amigos en Cuba【 RÁPIDO y SEGURO 】✔️ Mejores Ofertas ¡RÁPIDOS, BARATOS y SEGUROS!';
  // tslint:disable-next-line:max-line-length
  keywords = 'recarga a cuba, recargas para cuba, ofertas de recargas a cuba, promocion de recarga a cuba';

  DeepLinkType = DeepLinkType;

  constructor(
    private router: Router,
    private urlService: UrlService,
    private storageService: StorageService,
    private promotionService: PromotionsService,
    private htmlContent: HtmlContentService,
    private route: ActivatedRoute,
    private titleService: Title,
    private fingerprintService: FingerprintService,
    private metaService: Meta,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: Document,
    private googlePlayService: GooglePlayReviewsService,
    private rechargeService: RechargeService,
    private dataService: DataService,
    private datePipe: DatePipe,
    private vps: ViewportScroller,
  ) {
    super(urlService, fingerprintService, storageService, route, 'stu_btn_home');
    this.promotions = [];
    this.offers = [];
    this.promotionEndDate = '';
    this.expiredDate = '';
    this.offer = '';
    this.loadingInit = this.promotionService.loading$;
    this.route.data.subscribe((result) => {
      if (result.name == 'salir') {
        this.storageService.logout();
      }
    });
    this.cubatelServiceSizes = [{
      key: 'SM',
      services: this.cubatelServices.length >= 12 ? this.cubatelServices.slice(0, 12) : this.cubatelServices.slice(0, 9)
    },
      {
      key: 'MD',
      services: this.cubatelServices.slice(0, 10)
    }, {
        key: 'LG',
        services: this.cubatelServices.length > 12 ? this.cubatelServices.slice(0, 12) : this.cubatelServices.length == 11 ? this.cubatelServices.slice(0, 10) : this.cubatelServices
      }];
  }

  ngOnDestroy(): void {
    this.document.removeEventListener('scroll', this.activeCounter);
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.urlService.fragment$.subscribe(x => {
        if (x == 'recargas-a-cuba') {
          window.scroll(0, 150);
        }
        setTimeout(() => {
          switch (x) {
            case 'flights-section':
              this.vps.scrollToAnchor('flights-section');
              break;
            case 'hotel-section':
              this.vps.scrollToAnchor('hotel-section');
              break;
            case 'rent-a-carr-section':
              this.vps.scrollToAnchor('rent-a-carr-section');
              break;
            case 'formalities-section':
              this.vps.scrollToAnchor('formalities-section');
              break;
          }
        }, 200);
      });
      this.promotionService
        .getPromotionDetails()
        .then((promotionInfo: PromotionDetailsModel) => {
          if (promotionInfo) {
            this.flashOfferEnabled = promotionInfo.flashOfferEnabled;
            this.promotionService.flashOfferSubject.next(this.flashOfferEnabled);
            if (promotionInfo.promotions.length > 0) {
              this.promotions = promotionInfo.promotions.filter(
                (x) => x.serviceType == 0
              );
              this.offers = promotionInfo.promotions.filter(
                (x) => x.serviceType == 0
              );
              this.selectedOfferControl.setValue(this.promotions.find(x => x.defaultPrice)?.promotionAmountFullText || this.promotions[0].promotionAmountFullText);
              this.promoFormat(this.offers.find(x => x.defaultPrice) || this.offers[0], new Date(promotionInfo.promotionStartDate), new Date(promotionInfo.promotionEndDate));
            }
            if (
              promotionInfo.isPrerechargeMode ||
              promotionInfo.isPromotionMode
            ) {
              this.isPreRecharge = promotionInfo.isPrerechargeMode;
              this.isPromotion = true;
              this.promotionEndDate = promotionInfo.promotionEndDate;
              this.htmlContent.GetHtmlContentPromotion().then((promotion) => {
                if (promotion) {
                  this.offer = promotion[1] ? promotion[1].text : '';
                }
              });
            } else {
              this.isPromotion = false;
            }
          }
        });
    }

  }

  ngOnInit() {
    lazyLoad.loadStyle('home4', this.document);
    this.metaService.updateTag({
      name: 'description',
      content: this.description,
    });
    this.metaService.updateTag({name: 'keywords', content: this.keywords});
    this.metaService.updateTag({name: 'robots', content: 'all'});
    this.metaService.updateTag({property: 'og:title', content: this.title});
    this.metaService.updateTag({
      property: 'og:description',
      content: this.description,
    });
    this.metaService.updateTag({
      property: 'og:url',
      content: `${environment.host}${this.router.url}`,
    });
    this.titleService.setTitle(this.title);
    this.urlService.changeUrl(this.router.url);
    this.urlService.footer.emit();
    const user = this.storageService.getCurrentUser();
    this.userLevel = user ? user.userLevel : -1;
    this.storageService.currentUser.subscribe((user) => {
      this.currentUser = user;
      this.userLevel = this.currentUser ? this.currentUser.userLevel : -1;
    });
    const activeServices = this.cubatelServices.map(x => {
      if (x.name == 'Recargas') {
        x.link = this.isAuthenticated() ? '/recargas' : '';
      }
      return x;
    }).filter(x => x.active);
    // this.cubatelServices = activeServices.length > 12 ? activeServices.slice(0, 12) : activeServices.length == 11 ? activeServices.slice(0, 10) : activeServices;
    // this.cubatelServiceMd = activeServices.slice(0, 10);
    // this.cubatelServiceSM = activeServices.length >= 12 ? activeServices.slice(0, 12) : activeServices.slice(0, 9);

    this.storageService.promoCode.subscribe((promo) => {
      if (promo && promo?.code && (promo?.expiration + environment.expirationCode) > Date.now()) {
        this.useStuCode = promo.code.toUpperCase();
      } else {
        this.useStuCode = '';
        if (promo && promo?.code && (promo?.expiration + environment.expirationCode) < Date.now()) {
          this.storageService.removePromoCode();
        }
      }
    });

    this.route.queryParams
      .subscribe((result: any) => {
        if (result.stu_code) {
          this.vps.scrollToAnchor('recharges-section');
        } else {
        }
      });
    this.getAllReviews();
    if (isPlatformBrowser(this.platformId)) {
      this.document.addEventListener('mousemove', this.onLazyLoadContent, {passive: true});
      this.document.addEventListener('click', this.onLazyLoadContent, {passive: true});
      this.document.addEventListener('touchstart', this.onLazyLoadContent, {passive: true});
      this.document.addEventListener('scroll', this.activeCounter, {passive: true});
    }

  }

  sendRecharge() {
    if (((this.nautaAccount.invalid || this.nautaAccount.pristine) && this.nautaIsOpen) ||
      ((this.phoneNumberControl.invalid || this.phoneNumberControl.pristine) && this.rechargeIsOpen) ||
      this.selectedOfferControl.value == null || this.selectedOfferControl.invalid) {
      if (this.rechargeIsOpen) {
        this.phoneNumberControl.markAsDirty();
        this.phoneNumberUntoched = true;
      }
      if (this.nautaIsOpen) {
        this.nautaAccount.markAsDirty();
        this.nautaAccountUntoched = true;
      }
      this.selectedOfferControl.markAsDirty();
      this.selectedOfferUntoched = true;
      return;
    }
    const accounts: StuTransactionAccountModel[] = [{
      account: this.rechargeIsOpen ? this.phoneNumberControl.value : this.nautaAccount.value,
      contactName: '',
      guid: v4(),
      priceId: 0,
      serviceType: this.rechargeIsOpen ? 0 : 1
    }];

    const rechargeModel: RechargeModel = {
      accountsToRecharge: accounts,
      sendRechargeNow: (this.isPromotion && !this.isPreRecharge) || !this.isPromotion ? true : false,
      sendSms: false,
      message: '',
      landingPage: false,
      price: null,
      isExclusive: false,
      discount: 0,
      eligibleExtraDiscount: 0,
      cashback: 0,
      eligibleCashback: 0,
      availableCashback: 0,
      izcr: false,
      serviceType: this.rechargeIsOpen ? 0 : 1,
      isPrerechargeMode: this.isPreRecharge,
      maxCashback: 0,
      totalDiscount: 0,
      fromHome: true,
      rechargeAmount: this.promotions.find(x => x.promotionAmountFullText == this.selectedOfferControl.value)?.rechargeAmount,
      code: this.rechargeIsOpen ? this.useStuCode : '',
      promoType: this.promotions.find(x => x.promotionAmountFullText == this.selectedOfferControl.value)?.promoType,
      promotionAmountText: this.promotions.find(x => x.promotionAmountFullText == this.selectedOfferControl.value)?.promotionAmountFullText,
    };
    this.dataService.changeMessage(rechargeModel);
    this.router.navigate(['/verificacion-pago']);
  }

  signUp() {
    this.storageService.logout();
    this.storageService.authorizeSubject.next({authorize: false, url: '/'});
  }

  toggle(isRecharge: boolean) {
    if (isRecharge) {
      this.phoneNumberControl.reset();
      this.phoneNumberUntoched = false;
    } else {
      this.nautaAccount.reset();
      this.nautaAccountUntoched = false;
    }
    this.selectedOfferControl.reset();
    this.selectedOfferUntoched = false;
    if (isRecharge != this.rechargeIsOpen && isRecharge == this.nautaIsOpen) {
      const serviceType = isRecharge ? 0 : 1;
      this.getPromotions(serviceType);
      this.rechargeIsOpen = !this.rechargeIsOpen;
      this.nautaIsOpen = !this.nautaIsOpen;
    }
  }

  trackByPrice(item: PriceDetailsModel) {
    return item.promotionAmountFullText;
  }

  getFirst(word: string) {
    if (word != null) {
      return {letter: word.charAt(0).toUpperCase()};
    }
  }

  isAuthenticated() {
    return this.storageService.isAuthenticated();
  }

  redirectToRecharges() {
    console.log('test');
    if (!this.isAuthenticated()) {
      this.router.navigate(['/'], {state: {data: 'recarga-a-cuba'}});
      this.urlService.fragmentSubject.next('recargas-a-cuba');
    }
  }

  clickActions(id: string) {
    switch (id) {
      case 'Recargas':
        this.redirectToRecharges();
        break;
    }
  }

  callToAction1(tab: string = '') {
    if (tab == 'nauta' && !this.nautaIsOpen) {
      this.toggle(false);
    }
    if (tab == 'recharge' && !this.rechargeIsOpen) {
      this.toggle(true);
    }
    const item = this.document.querySelector('#promotions .carousel-item.active').getAttribute('data-index');
    switch (item) {
      case '0':
        this.vps.scrollToAnchor('recharges-section');
        break;
      case '1':
        this.router.navigate(['/llamadas-a-cuba']);
        break;
      default:
        this.storageService.authorizeSubject.next({'authorize': false, 'url': '/'});
        break;
    }
  }

  private getExternalReviewsInit() {
    this.googlePlayService.listRewards().then((result: ExternalReviews) => {
      if (result.success == true) {
        loadBootsrtapNative();
        this.from = result.from;
        this.size = result.size;
        this.hasMore = result.hasMore;
        this.reviews = result.reviews;
        let count = 0;
        this.reviews.forEach((element, index) => {
          if (count == 3) {
            count = 0;
          }
          element.color = color[count];
          count++;
        });
        this.getAllReviews();
      }
    });
  }

  private getAllReviews() {
    let desktopLimit = 0;
    let desktopLimitArray = [];
    this.reviews.forEach((element) => {
      desktopLimitArray.push(element);
      desktopLimit++;
      if (desktopLimit == 3) {
        this.reviewsDesktop.push(desktopLimitArray);
        desktopLimitArray = [];
        desktopLimit = 0;
      }
    });
  }

  private getPromotions(serviceType: number): void {
    this.promotionService
      .getPromotionDetails()
      .then((promotionInfo: PromotionDetailsModel) => {
        if (promotionInfo && promotionInfo.promotions.length > 0) {
          this.promotions = promotionInfo.promotions.filter(
            (x) => x.serviceType === serviceType
          );
          const amountText = serviceType === 0 ? this.promotions.find(x => x.defaultPrice)?.promotionAmountFullText || this.promotions[0]?.promotionAmountFullText : this.promotions[0].promotionAmountFullText;
          this.selectedOfferControl.setValue(amountText);
        }
      });
  }

  private onLazyLoadContent = () => {
    const self = this;
    //#region 3rd Party Resources
    if (isPlatformBrowser(this.platformId)) {
      this.getExternalReviewsInit();
      this.document.removeEventListener('mousemove', this.onLazyLoadContent);
      this.document.removeEventListener('click', this.onLazyLoadContent);
      this.document.removeEventListener('touchstart', this.onLazyLoadContent);
    }
    //#endregion
  };

  private activeCounter = () => {
    const userCounterId = this.document.getElementById('user-counter');
    if (this.isInViewport(userCounterId)) {
      this.document.removeEventListener('scroll', this.activeCounter);
      this.counter(7, 1000000, 2500, 0);
      this.counter(7, 1000000, 2500, 1);
      this.counter(11, 194, 1, 2);
    }
  };

  private counter(milliseconds: number, base: number, range: number, types: number) {
    this.userCounter = 0;
    this.appsCounter = 0;
    this.countriesCounter = 0;
    let items = 0;
    const interval = setInterval(() => {
      if (items < base) {
        items += range;
        types == 0 ? this.userCounter += range : types == 1 ? this.appsCounter += range : this.countriesCounter += range;
      } else {
        items = base;
        types == 0 ? this.userCounter = base : types == 1 ? this.appsCounter = base : this.countriesCounter = base;
        clearInterval(interval);
      }
    }, milliseconds);
  }

  private isInViewport(element: HTMLElement): boolean {
    if (element) {
      const rect = element.getBoundingClientRect();
      return (rect.top >= 0 && rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || this.document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || this.document.documentElement.clientWidth));
    }
  }

  private promoFormat(promotion: PriceDetailsModel, startDate: Date, endDate: Date) {
    const bonos = promotion.promotionAmountFullText.split('+');
    this.promotionAmountFullText = promotion.promotionAmountFullText;
    if (startDate.getMonth() == endDate.getMonth()) {
      this.promoDateText =
        `${this.datePipe.transform(startDate, 'd')} al ${this.datePipe.transform(endDate, 'd')} de ${months[startDate.getMonth()]} `;
      this.expiredDate =
        `Del ${this.datePipe.transform(startDate, 'd')} al ${this.datePipe.transform(endDate, 'd')} <br> de ${months[endDate.getMonth()]}`;
    } else {
      this.promoDateText =
        `${this.datePipe.transform(startDate, 'd')} de
      ${months[startDate.getMonth()]} al ${this.datePipe.transform(endDate, 'd')} de ${months[endDate.getMonth()]}`;
      this.expiredDate = `Del ${this.datePipe.transform(startDate, 'dd/MM')} al<br>${this.datePipe.transform(endDate, 'dd/MM')}`;
    }

    let html = promotion.promotionAmountFullText;
    /*for (let index = 1; index < bonos.length; index++) {
      if (bonos[index]) {
        html += `<br>+ ${bonos[index]}`;
      }
    }*/
    if (html.length <= 10) {
      html = `Reciben<br>${html}`;
    }
    this.promoTopHtml = html;
  }
}
