import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class FirebaseProductGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (route.queryParams.stu_product ) {
      if (route.queryParams.stu_provider) {
        if (route.queryParams.stu_provider == '1') {
          this.router.navigate([`/market/productos-compras-cuba-online/amazon/${route.queryParams.stu_product}`]);
        } else if (route.queryParams.stu_provider == '0') {
          this.router.navigate([`/market/productos-compras-cuba-online/cubatel/${route.queryParams.stu_product}`]);
        } else if (route.queryParams.stu_provider == '2') {
          this.router.navigate([`/market/productos-compras-cuba-online/shein/${route.queryParams.stu_product}`]);
        } else { this.router.navigate([`/market`]); }
      } else {
        this.router.navigate([`/market`]);
      }
    } else {
      this.router.navigate([`/market`]);
    }
    return false;
  }
}
