export const environment = {
  production: false,
  signature_key: 'PzyLzFCOLLtbw-gxAAFUAjh-3SDgznZm',
  baseUrl: 'https://sharptopup.com/server/',
  //baseUrl: 'https://localhost:5001/',
  client_id: 'f0ed0633-f8ed-df96-7211-35cc830e955e',
  gcId: '214805215376-gubou9ilf14uc811lcdslautu9hjejak.apps.googleusercontent.com',
  redirectPayPalUrl: 'https://www.smarttopup.us/redirect-dev.html?payPalUrl=',
  rewardsUrl: 'https://www.googleapis.com/androidpublisher/v3/applications/com.cubatel.android/reviews',
  authorizeAccountGoogle: 'https://accounts.google.com/o/oauth2/token',
  locale: 'es',
  lazyLoadLocation: 'https://dev-web.recargahoy.com/',
  fpApiToken: 'jxNr6s4iwzOt99QKkPcC',
  sendFingerprint: true,
  host: 'https://dev-web.recargahoy.com',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.cubatel.android',
  appleStoreUrl: 'https://apps.apple.com/us/app/cubatel/id1300396049',
  expirationCode: 259200000,
  expAmazonWizard: 604800000,
  turn_on_shein: false,
  turn_on_amazon: false
};
