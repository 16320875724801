import {environment} from '@env/environment';

export class CubatelServiceInterface {
  name: string;
  desktop_width: string;
  mobile_width: string;
  height: string;
  badge?: CubatelServiceBadgeInterface;
  imgSrc: string;
  link: string;
  active: boolean;
  tel?: boolean;
}

export class CubatelServiceSize {
  key: string;
  services: CubatelServiceInterface[];
}

export interface CubatelServiceBadgeInterface {
  name: string;
  styles: string;
  classList: string;
}

export const cubatel_service: CubatelServiceInterface[] = [
  {name: 'Recargas', active: true, desktop_width: '34', mobile_width: '28', height: 'auto', imgSrc: 'assets/home/service-recharge.svg', link: ''},
  {name: 'Llamadas', active: true, desktop_width: '34', mobile_width: '28', height: 'auto', imgSrc: 'assets/home/service-call.svg', link: '/llamadas-a-cuba'},
  {name: 'Market', active: true, desktop_width: '34', mobile_width: '28', height: 'auto', imgSrc: 'assets/home/service-market.svg', link: '/market'},
  {name: 'Remesas', active: true, desktop_width: '38', mobile_width: '28', height: 'auto', imgSrc: 'assets/home/service-remittance.svg', link: '/envio-dinero-cuba'},
  {name: 'Amazon', active: environment.turn_on_amazon, desktop_width: '34', mobile_width: '28', height: 'auto', imgSrc: 'assets/home/icon_amazon.svg', link: '/market/envios/amazon-para-cuba'},
  {name: 'Shein', active: environment.turn_on_shein, desktop_width: '34', mobile_width: '28', height: 'auto', imgSrc: 'assets/market-shein/shein.svg', link: '/market/envios/shein-para-cuba', badge: {name: 'Nuevo', classList: 'bg-danger rounded-pill text-white px-2 mb-0 position-absolute d-flex', styles: 'right: -10px; top: 0px'}},
  {name: 'Nauta', active: true, desktop_width: '34', mobile_width: '28', height: 'auto', imgSrc: 'assets/home/service-nauta.svg ', link: '/recargas-nauta'},
  {name: 'SMS', active: true, desktop_width: '34', mobile_width: '28', height: 'auto', imgSrc: 'assets/home/service-sms.svg', link: '/sms-a-cuba'},
  {name: 'Envíos', active: true, desktop_width: '34', mobile_width: '28', height: 'auto', imgSrc: 'assets/home/service-shipment.svg', link: 'market/tienda-online-cuba/productos-envio-cuba'},
  {name: 'Hoteles', active: true, desktop_width: '34', mobile_width: '28', height: 'auto', imgSrc: 'assets/home/service-hotel.svg', link: 'tel:+18332822835', tel: true},
  {name: 'Autos', active: true, desktop_width: '34', mobile_width: '28', height: 'auto', imgSrc: 'assets/home/service-rent-a-carr.svg', link: 'tel:18332822835', tel: true},
  {name: 'Vuelos', active: true, desktop_width: '34', mobile_width: '28', height: 'auto', imgSrc: 'assets/home/service-flight.svg', link: 'tel:18332822835', tel: true},
  {name: 'Trámites', active: true, desktop_width: '34', mobile_width: '28', height: 'auto', imgSrc: 'assets/home/service-formalities.svg', link: 'tel:18332822835', tel: true},
];
