<header>
  <!--HEADER-->
  <nav class="navbar navbar-expand-md py-2 pt-md-3 market"
       id="mainNav">

    <div class="container px-2 align-items-center align-items-md-end">
      <button aria-label="Men&uacute;" class="pb-0 pt-3 navbar-toggler toggler-menu mr-auto" type="button">
        <input id="menu-close" aria-label="Cerrar" type="checkbox" [formControl]="checkboxControl" />
        <label for="menu-close" class="hamburguer mb-0">
          <span class="market"></span>
          <span class="market"></span>
          <span class="market"></span>
        </label>
        <div class="col-lg-12 menu-container main pr-0" id="app-menu">
          <div class="d-flex justify-content-around">
            <div></div>
            <a [routerLink]="['/']" routerLinkActive="" aria-label="Inicio" (click)="collapse()">
              <img class="d-block d-xl-none lazyload" width="160" height="42" src="assets/logo_color.svg"
                   alt="Cubatel Market | Tu tienda de envíos a Cuba" />
            </a>
            <a class="nav-link d-flex stu-default-color" (click)="collapse()" aria-label="cubatel.com"
               routerLink="/perfil" routerLinkActive="active">
              <i class="stu stu-icon-profile"></i>
            </a>
          </div>
          <div class="menu d-lg-flex flex-lg-column pt-4">
            <ul class="navbar-nav mr-auto menu">
              <li class="nav-item active">
                <a *ngIf="isAuthenticated()" class="nav-link d-flex stu-primary-color font-weight-bold OpenSans position-relative"
                   aria-label="cubatel.com" [routerLink]="routerLinkRecarga" routerLinkActive="active"
                   (click)="collapse()">
                  Recarga Celular
                  <p *ngIf="isPromotion || isPreRechargeMode" class="rounded-pill text-white mb-0 position-absolute new-mark d-flex align-items-center bg-danger p-1" style="left: 140px !important;top: -5px; padding: 0 5px"><small>Promo</small></p>
                </a>
                <a *ngIf="!isAuthenticated()" class="nav-link d-flex stu-primary-color font-weight-bold OpenSans position-relative"
                   aria-label="cubatel.com" [routerLink]="" routerLinkActive="active" (click)="collapse()"
                   (click)="redirectToRecharges()">
                  Recarga Celular
                  <p *ngIf="isPromotion || isPreRechargeMode" class="rounded-pill text-white mb-0 position-absolute new-mark d-flex align-items-center bg-danger p-1" style="left: 140px !important;top: -5px; padding: 0 5px"><small>Promo</small></p>
                </a>
              </li>
              <li class="nav-item active">
                <a class="nav-link d-flex stu-primary-color font-weight-bold position-relative OpenSans" aria-label="cubatel.com"
                   routerLink="/market" routerLinkActive="active" (click)="collapse()">
                  Market
                  <!--<p class="ml-2 bg-danger rounded-pill text-white p-1 mb-0 position-absolute new-mark" style="left: 40px;bottom: 10px;"><small>New</small></p>-->
                </a>
              </li>
              <li class="nav-item active">
                <a class="nav-link d-flex stu-primary-color font-weight-bold OpenSans" aria-label="cubatel.com"
                   routerLink="/envio-dinero-cuba" routerLinkActive="active" (click)="collapse()">
                  Remesas a Cuba

                </a>
              </li>
              <li class="nav-item active">
                <a class="nav-link d-flex stu-primary-color font-weight-bold OpenSans" aria-label="cubatel.com"
                   [routerLink]="routerLinkNauta" routerLinkActive="active" (click)="collapse()">
                  Internet
                </a>
              </li>
              <!--<li class="nav-item active">
                <a class="nav-link d-flex stu-primary-color font-weight-bold position-relative" aria-label="cubatel.com"
                  href="https://click.withmaza.com/Tad2/Cubatela" target="_blank" routerLinkActive="active"
                  (click)="collapse()">
                  Tarjeta de Débito Visa
                  <p class="ml-2 bg-danger rounded-pill text-white p-1 mb-0"><small>New</small></p>
                </a>
              </li>-->

              <li class="nav-item active">
                <a class="nav-link d-flex stu-primary-color font-weight-bold OpenSans" aria-label="cubatel.com"
                   routerLink="/llamadas-a-cuba" routerLinkActive="active" (click)="collapse()">
                  Llamadas
                </a>
              </li>
              <li class="nav-item active">
                <a class="nav-link d-flex stu-primary-color font-weight-bold position-relative OpenSans" aria-label="cubatel.com"
                   routerLink="/mayorista" routerLinkActive="active" (click)="collapse()">
                  Recargas Mayoristas
                </a>
              </li>
              <li *ngIf="turnOnAmazon" class="nav-item active">
                <a class="nav-link d-flex stu-primary-color font-weight-bold position-relative OpenSans" aria-label="cubatel.com"
                   routerLink="market/envios/amazon-para-cuba" routerLinkActive="active" (click)="collapse()">
                  Amazon
                </a>
              </li>
              <li *ngIf="turnOnShein" class="nav-item active">
                <a class="nav-link d-flex stu-primary-color font-weight-bold position-relative OpenSans" aria-label="cubatel.com"
                   routerLink="market/envios/shein-para-cuba" routerLinkActive="active" (click)="collapse()">
                  Shein
                  <p class="rounded-pill text-white mb-0 position-absolute new-mark d-flex align-items-center bg-danger p-1" style="left: 50px !important;top: -5px; padding: 0 5px"><small>Nuevo</small></p>
                </a>
              </li>
              <li class="nav-item active">
                <a class="nav-link d-flex stu-primary-color font-weight-bold OpenSans" aria-label="cubatel.com"
                   [routerLink]="routerLinkSms" routerLinkActive="active" (click)="collapse()">
                  Mensajes de Texto
                </a>
              </li>
              <li class="nav-item active">
                <a class="nav-link d-flex stu-primary-color font-weight-bold position-relative OpenSans" aria-label="cubatel.com"
                   routerLink="market/pedidos" routerLinkActive="active" (click)="collapse()">
                  Mis pedidos
                </a>
              </li>
              <li class="nav-item active">
                <a class="nav-link d-flex stu-primary-color font-weight-bold OpenSans" aria-label="cubatel.com"
                   routerLink="/asistencia" routerLinkActive="active" (click)="collapse()">
                  Ayuda
                </a>
              </li>
              <li *ngIf="!isAuthenticated()" class="nav-item active mt-2 OpenSans">
                <a class="btn btn-primary stu-bg-primary text-white font-weight-bold rounded-pill px-3 py-1"
                   aria-label="cubatel.com" routerLink="/recargas" routerLinkActive="active">
                  Iniciar sesión
                </a>
              </li>
              <li *ngIf="isAuthenticated()" class="nav-item active OpenSans">
                <a class="nav-link d-flex stu-primary-color font-weight-bold" aria-label="cubatel.com"
                   routerLink="/salir" routerLinkActive="active" (click)="collapse()">
                  Salir
                </a>
              </li>
            </ul>
          </div>
          <div id="social-networks" class="d-flex flex-column mt-auto">
            <div class="text-left position-relative">
              <a class="instagram" href="https://www.instagram.com/cubateloficial/" rel="noreferrer" target="_blank"
                 aria-label="Instagram"><img width="34.71" height="34.71" alt="Instagram"
                                             src="https://www.cubatel.com/images-ads/cdn/assets-v3/menu/instagram.svg"></a>
              <a class=" facebook mx-3" href="https://www.facebook.com/cubateloficial" rel="noreferrer" target="_blank"
                 aria-label="Facebook"><img width="7.54" height="16.6" alt="Facebook"
                                            src="https://www.cubatel.com/images-ads/cdn/assets-v3/menu/facebook.svg"></a>
              <a class="youtube mr-3" href="https://www.youtube.com/channel/UC0HcpYElH8p_9DFF6OmfCrw" rel="noreferrer"
                 target="_blank" aria-label="Youtube"><img width="32.11" height="16.61" alt="Youtube"
                                                           src="https://www.cubatel.com/images-ads/cdn/assets-v3/menu/youtube.svg"></a>
              <a class="telegram" href="https://t.me/s/cubateloficial?before=64" rel="noreferrer" target="_blank"
                 aria-label="Telegram"><img width="22" height="22" alt="Telegram"
                                            src="https://www.cubatel.com/images-ads/cdn/assets-v3/menu/telegram.svg"></a>
            </div>

            <p id="copyright" class="text-left stu-primary-color mt-3 font-weight-bold">Copyright © Cubatel 2021<br>
              Todos los Derechos Reservados® </p>
            <a id="terms" target="_blank" class="text-left stu-primary-color text-decoration-none font-weight-bold"
               routerLink="terminos">Términos &
              Condiciones</a>
          </div>
        </div>
      </button>
      <!--<a *ngIf="!isRemittancePage" class="navbar-brand" [routerLink]="['/']">
        <img width="200" height="53" class="d-none d-xl-block" src="assets/logo_white.svg"
             alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
        <img width="160" height="42" class="d-block d-xl-none" src="assets/logo_white.svg"
             alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      </a>-->
      <a *ngIf="!isRemittancePage" class="navbar-brand" [routerLink]="['/']" aria-label="Inicio">
        <img class="d-none d-xl-block lazyload" width="200" height="53" src="assets/logo_color.svg"
             alt="Cubatel Market | Tu tienda de envíos a Cuba" />
        <img class="d-block d-xl-none lazyload" width="160" height="42" src="assets/logo_color.svg"
             alt="Cubatel Market | Tu tienda de envíos a Cuba" />

      </a>
      <a *ngIf="isRemittancePage" class="navbar-brand" [routerLink]="['/']">
        <img width="200" height="53" class="d-none d-xl-block"
             src="assets/logo_remittance.svg"
             alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
        <img width="160" height="42" class="d-block d-xl-none"
             src="assets/logo_remittance.svg"
             alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      </a>
      <a *ngIf="!isMarketPage" class="nav-link d-flex stu-default-color d-flex d-md-none ml-auto auth" (click)="collapse()"
         aria-label="cubatel.com" routerLink="/perfil" routerLinkActive="active" >
        <i class="stu stu-icon-profile p-2"></i>
      </a>
      <div *ngIf="isMarketPage" class="d-block d-md-none carts-area p-0 position-relative ml-auto">
        <div class="cart-box ml-4">

          <a  routerLink="market/tienda-online-cuba/productos/carrito" data-toggle="tooltip" data-placement="top" title="Carro de compra" class="cart-btn">
            <img class="cartResponsive" width="23" src="assets/market/icon-cart.svg" alt="cart">
            <span class="position-absolute">
              {{(shoppingCart$ | async)?.data.totalItems}}
            </span>
          </a>

         <!--  <a href="market/tienda-online-cuba/productos/carrito" data-toggle="tooltip" data-placement="top" title="Carro de compra" class="cart-btn">
            <img class="cartResponsive" width="23" src="assets/market/icon-cart.svg" alt="cart">
            <span class="position-absolute">
              {{(shoppingCart$ | async)?.data.totalItems}}
            </span>
          </a> -->

        </div>
      </div>
      <span *ngIf="user?.userLevel == 1 && (availableCashback == 0 || (availableDiscount != 0))"
            class="rewards d-flex d-sm-none position-relative align-self-center justify-content-center align-items-end ml-auto p-2"
            (click)="onRewards(false)">
        <i class="stu stu-icon-reward white user "></i>
        <p class="position-absolute discount mb-0 text-center text-white">
          <span class="bold">{{availableDiscount}}</span>
        </p>
      </span>
      <span *ngIf="user?.userLevel == 1 && availableDiscount == 0 && availableCashback != 0 "
            class="d-flex d-sm-none position-relative align-items-center ml-auto p-2" (click)="onRewards(true)">
        <i class="stu stu-icon-cashback white user "></i>
        <p class="mb-0 cashback ml-1 text-center text-white">
          <span class="bold">{{availableCashback}}</span>
        </p>
      </span>
      <div class="pb-2">
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto mr-3 ">
            <li class="nav-item d-flex align-items-end">
              <img class=" mb-1" width="20" height="19" alt="Market" src="assets/menu/menu-recharge.svg"/>
              <a *ngIf="isAuthenticated()" class="nav-link me-lg-3 OpenSans font-weight-bold mb-1 mr-3 text-secondary position-relative"
                 [routerLink]="routerLinkRecarga">
                <span class="d-block">RECARGAS
                  <p *ngIf="isPromotion || isPreRechargeMode" class="bg-danger rounded-pill text-white px-1 mb-0 position-absolute d-flex"
                     style="right: -15px; top: -14px;"><small>Promo</small></p>
                </span>
              </a>
              <a *ngIf="!isAuthenticated()" class="nav-link me-lg-3 OpenSans font-weight-bold mb-1 mr-3 text-secondary position-relative"
                 [routerLink]="['/']" (click)="redirectToRecharges()">
                <span class="d-block">RECARGAS
                  <p *ngIf="isPromotion || isPreRechargeMode" class="bg-danger rounded-pill text-white px-1 mb-0 position-absolute d-flex"
                     style="right: -15px; top: -14px;"><small>Promo</small></p>
                </span>
              </a>
            </li>
            <li class="nav-item d-flex align-items-end">
              <img class=" mb-1" width="20" height="19" alt="Market" src="assets/menu/menu-market.svg"/>
              <a class="nav-link me-lg-3 OpenSans text-secondary font-weight-bold position-relative mb-1 mr-3" [routerLink]="['/market']" [ngClass]="isMarketPage? 'market' : ''"
                 routerLinkActive="" (click)="market()">
                MARKET
                <!--<p *ngIf="!isPromotion && !isPreRechargeMode && (isLoadingPromo | async) == false" class="bg-danger rounded-pill text-white px-1 mb-0 position-absolute d-flex"
                   style="right: -15px; top: -12px;"><small>Hot</small></p>-->
              </a>
            </li>
            <li class="nav-item d-flex align-items-end">
              <img class=" mb-1" width="20" height="19" alt="Remesas" src="assets/menu/menu-remittance.svg"/>
              <a class="nav-link me-lg-3 OpenSans font-weight-bold mb-1 mr-3 text-secondary"
                 [routerLink]="['/envio-dinero-cuba']" routerLinkActive="">
                REMESAS
                <!--<p class="bg-danger rounded-pill text-white px-1 mb-0 position-absolute d-flex"
                  style="right: -10px; top: -2px;"><small>New</small></p>-->
              </a>
            </li>
            <li class="nav-item d-lg-flex d-none align-items-end">
              <img class="mb-1 " width="20" height="19" alt="Internet" src="assets/menu/menu-internet.svg"/>
              <a class="nav-link me-lg-3 OpenSans text-white font-weight-bold mb-1 mr-3 text-secondary" routerLinkActive=""
                                                           [routerLink]="routerLinkNauta">
              INTERNET
            </a>
            </li>
            <li class="nav-item d-flex align-items-end">
              <img class=" mb-1" width="20" height="19" alt="Llamadas" src="assets/menu/menu-call.svg"/>
              <a class="nav-link me-lg-3 OpenSans text-secondary font-weight-bold mb-1 mr-3" routerLinkActive=""
                 [routerLink]="['/llamadas-a-cuba']">
                LLAMADAS
              </a>
            </li>
            <li class="nav-item d-xl-flex d-none align-items-end">
              <img class="mb-1 " width="20" height="19" alt="Mensaje de texto" src="assets/menu/menu-sms.svg"/>
              <a class="nav-link me-lg-3 OpenSans text-white font-weight-bold mb-1  text-secondary" routerLinkActive=""
                 routerLink="sms-a-cuba">
                SMS
              </a>
            </li>

            <li *ngIf="isAuthenticated()" class="nav-item active d-flex align-items-end">
              <a class="nav-link me-lg-3 OpenSans font-weight-bold mb-1 text-secondary" aria-label="cubatel.com"
                 routerLink="/salir" routerLinkActive="active">
                Salir
              </a>
            </li>
          </ul>
          <button id="signin-link" (click)="checkboxAuthControl.setValue(true)"
                  class="d-flex btn OpenSans register-toggler font-weight-bold btn-primary stu-bg-primary text-white"
                  *ngIf="!isAuthenticated()" type="button">
            <span class="d-md-block d-lg-none">Iniciar</span>
            <span class="d-lg-block d-none">Iniciar sesión</span>
          </button>
          <div class="carts-area p-0 position-relative">
            <div *ngIf="this.router.url.includes('market')" class="cart-box ml-4">
              <a  routerLink="market/tienda-online-cuba/productos/carrito" data-toggle="tooltip" data-placement="top" title="Carro de compra" class="cart-btn">
                <img class="cart" width="23" src="assets/market/icon-cart.svg" alt="cart">
                <span class="position-absolute">
                  {{(shoppingCart$ | async)?.data.totalItems}}
                </span>
              </a>

             <!--  <a  href="market/tienda-online-cuba/productos/carrito" data-toggle="tooltip" data-placement="top" title="Shopping Cart" class="cart-btn">
                <img class="cart" width="23" src="assets/market/icon-cart.svg" alt="cart">
                <span class="position-absolute">
                  {{(shoppingCart$ | async)?.data.totalItems}}
                </span>
              </a> -->

            </div>
          </div>
          <!--<a class="text-decoration-none" aria-label="Carro de compras" routerLink="/"><i
            class="stu stu-shop-car white mx-3"></i></a>-->
          <!--/////REWARDS/////-->
          <span *ngIf="user?.userLevel == 1 && (availableCashback == 0 || (availableDiscount != 0))"
                class="rewards navbar-toggler d-none d-sm-flex position-relative align-self-start justify-content-center align-items-end"
                (click)="onRewards(false)">
            <i class="stu stu-icon-reward  white  user"></i>
            <p class="position-absolute discount mb-0 text-center text-white"><span
              class="bold">{{availableDiscount}}</span>
            </p>
          </span>
          <!--/////REWARDS/////-->

          <!--/////CASHBACK/////-->
          <span *ngIf="user?.userLevel == 1 && availableDiscount == 0 && availableCashback != 0"
                class="d-none d-sm-flex position-relative align-items-center" (click)="onRewards(true)">
            <i class="stu stu-icon-cashback white user"></i>
            <p class="mb-0 cashback ml-1 text-cente text-white">
              <span class="bold">{{availableCashback}}</span>
            </p>
          </span>

          <!--/////CASHBACK/////-->

          <a *ngIf="isAuthenticated()" class="nav-link d-flex stu-default-color pt-1 ml-3" aria-label="cubatel.com"
             routerLink="/perfil" routerLinkActive="active">
            <i class="stu stu-icon-profile"></i>
          </a>
        </div>
      </div>
    </div>
  </nav>
  <!--END-HEADER-->
</header>
<div *ngIf="mainPage && notificationEnabled" [ngClass]="{'top-promotion': isScrollUp}"
     class="stu-notifications text-center promotion w-100 mt-0 stu-notification-slider">
  <div *ngFor="let notification of notificationToView; let index = index" class="stu-notification-container"
       [class.active]="index == 0">
    <span class="stu-notification-item" [innerHTML]="notification.items[0].title"></span>
    <span class="stu-notification-item" [innerHTML]="notification.items[0].body"></span>
    <a class="stu-notification-item banner-button" *ngIf="notification.items[0].actions.length > 0"
       [routerLink]="notification.items[0].actions[0].value" [innerHTML]="notification.items[0].actions[0].text"></a>
  </div>
</div>
<section *ngIf="!isMarketPage" class="menu-area3 bar3" style="position: fixed; background: white; width: 100%; box-shadow: 0px 2px 8px -6px grey;">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 d-flex align-items-center py-1 py-md-0">
        <div class="main-menu w-100" style="cursor: pointer">
          <ul class="list-unstyled list-inline d-flex justify-content-between mb-0">
            <li class="list-inline-item mega-menu mr-0" id="mega-menu">
              <span class="d-none d-md-flex link-separator">
                <a class="mr-5" routerLink="market/vender-mercado-online-cuba">Trabaja con Cubatel</a>
                <a class="mr-5" href="https://www.cubatel.com/blog/?utm_source=cubatel&utm_campaign=btn_banner_cubatel&utm_medium=website" target="_blank">Blog</a>
                <a class="mr-5" routerLink="asistencia">Ayuda</a>
              </span>
              <span class="d-flex d-md-none justify-content-around">
                <a *ngIf="isAuthenticated()" class="mr-4 d-flex align-items-center" routerLink="recargas">
                  <img class="d-block d-md-none mr-2" width="16" height="15" alt="Market" src="assets/menu/menu-recharge.svg"/>
                  <img class="d-none d-md-block mr-2" width="20" height="19" alt="Market" src="assets/menu/menu-recharge.svg"/>
                  <p *ngIf="isPromotion || isPreRechargeMode" class="rounded-pill text-white mb-0 position-absolute new-mark d-flex align-items-center bg-danger" style="top: 6px; padding: 0 5px"><small>Promo</small></p>
                Recargas
                </a>
                <a *ngIf="!isAuthenticated()" class="d-flex align-items-center text-secondary" [routerLink]="" routerLinkActive="active" (click)="redirectToRecharges()">
                  <img class="d-block d-md-none mr-2" width="16" height="15" alt="Market" src="assets/menu/menu-recharge.svg"/>
                  <img class="d-none d-md-block mr-2" width="20" height="19" alt="Market" src="assets/menu/menu-recharge.svg"/>
                  <p *ngIf="isPromotion || isPreRechargeMode" class="rounded-pill text-white mb-0 position-absolute new-mark d-flex align-items-center bg-danger" style="top: 6px; padding: 0 5px"><small>Promo</small></p>
                Recargas
                </a>
                <a class="d-flex align-items-center text-secondary" routerLink="market">
                  <img class="d-block d-md-none mr-2" width="16" height="15" alt="Market" src="assets/menu/menu-market.svg"/>
                  <img class="d-none d-md-block mr-2" width="20" height="19" alt="Market" src="assets/menu/menu-market.svg"/>
                Market
                </a>
                <a class="d-flex align-items-center text-secondary" routerLink="envio-dinero-cuba">
                  <img class="d-block d-md-none mb-1 mr-2" width="16" height="15" alt="Market" src="assets/menu/menu-remittance.svg"/>
                  <img class="d-none d-md-block mr-2" width="20" height="19" alt="Market" src="assets/menu/menu-remittance.svg"/>
                Remesas
                </a>
              </span>
            </li>
            <!--   <li class="list-inline-item d-none d-md-flex align-items-center">
                 <a routerLink="productos-compras-cuba-online">Productos</a>
               </li> -->

            <li class="list-inline-item d-none d-md-flex align-items-center">
              <a routerLink="market/tienda-online-cuba/productos-envio-cuba" >Envíos</a>
            </li>
            <li *ngIf="turnOnAmazon" class="list-inline-item d-none d-md-flex align-items-center">
              <a class="position-relative" routerLink="market/envios/amazon-para-cuba" >Amazon</a>
            </li>
            <li *ngIf="turnOnShein" class="list-inline-item d-none d-md-flex align-items-center">
              <a class="position-relative" routerLink="market/envios/shein-para-cuba" >Shein<p style="top: -70%; left: 50%; padding: 1px 22px; height: 21px" class="bg-danger rounded-pill text-white px-1 mb-0 position-absolute d-flex"><small >Nuevo</small></p></a>
            </li>
            <li class="list-inline-item d-none d-md-flex align-items-center">
              <a routerLink="" (click)="navigateToHome('flights-section');vps.scrollToAnchor('flights-section')">Viajes</a>
            </li>
            <li class="list-inline-item d-none d-lg-flex align-items-center">
              <a routerLink="" (click)="navigateToHome('hotel-section');vps.scrollToAnchor('hotel-section')">Hoteles y Excursiones</a>
            </li>
            <li class="list-inline-item d-none d-lg-flex align-items-center">
              <a routerLink="" (click)="navigateToHome('rent-a-carr-section');vps.scrollToAnchor('rent-a-carr-section')">Renta de Autos</a>
            </li>
          </ul>
        </div>
        <div *isAuthenticated class="main-menu pt-0 d-none d-md-flex pl-4 ml-xl-auto pr-xl-5">
          <div class="top-left d-flex align-items-center">
            <ul class="list-unstyled list-inline d-flex justify-content-between justify-content-md-start">
              <li class="list-inline-item">
                <a routerLink="pedidos">Mis pedidos</a>
              </li>
              <li *ngIf="(shoppingCart$ | async)?.data.totalItems > 0" class="list-inline-item cup-btn"><a routerLink="revisar-completar-pedido">Ir a pagar</a></li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
<div *ngIf="loadingInit" class="backdrop" style="border-radius: 8px; z-index: 1;">
  <div class="text-center">
    <picture>
      <source srcset="assets/loading.webp" type="image/webp">
      <source srcset="assets/loading.gif" type="image/gif">
      <img style="height: 70px; width:70px" src="assets/imgs/loading.gif" alt="Cargando...">
    </picture>
  </div>
</div>
